import type { DataObject } from 'o365-dataobject';
import { $t } from 'o365-utils';
import { userSession, context, configurableRegister } from 'o365-modules';

const exportData = async function (pUrl: string, pConfig: Object): Promise<void> {
    if (pConfig['exportConfig'].localizeDateTime === true) {
        pConfig['requestData'].localizeDateTime = true;
        pConfig['requestData'].timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    const body = {
        requestData: pConfig['requestData'],
        exportConfig: pConfig['exportConfig']
    };

    const response = await fetch(pUrl, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({
            'Content-Type': 'application/json',
            'X-NT-API': 'true'
        })
    });

    if (response.status === 200) {
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = pUrl.split('/').pop()!;
        link.click();

        URL.revokeObjectURL(blobUrl);
    } else {
        let message = ''; 
        try {
            const json = await response.json();
            message = json.error ?? $t('Data export failed.');
        } catch (ex) {
            message = $t('Data export failed.');
        }
        throw new Error(message);
    }
}

const dataObjectExportData = function (pDataObject: DataObject, pExportConfig: IExportConfig, pRequestData: Object, pFormat: ExportFormat | string): Promise<void> {
    const name = $t(pExportConfig?.fileName ?? pDataObject.export?.getFileName() ?? pDataObject.viewName.split("_").pop() ?? 'ExportData');

    let requestData = pDataObject.recordSource.getOptions();
    let exportConfig = new ExportConfig();
    exportConfig.created = pDataObject.export?.getExportDate();
    exportConfig.contextId = context.id;
    exportConfig.columns = pExportConfig?.['columns'] ?? pDataObject.fields.fields;

    exportConfig.columns.forEach((col) => {
        // Map formats to user session
        if (col.format) {
            col.format = userSession.formats[col.format] ?? col.format;
        }

        // Push absent fields to requestData
        if (requestData?.fields?.some((f:any) => col.name.toLowerCase() === f.name.toLowerCase())) return;
        requestData?.fields?.push(col);

        
    });

    if(requestData?.fields){
        const vFieldsToRemove:Array<string> = [];
        requestData?.fields.forEach((f:any)=>{
            if(!exportConfig.columns.find(x=>x.name == f.name) && f.sortOrder == undefined && f.groupByOrder == undefined){
                vFieldsToRemove.push(f.name);
            }
        });
        vFieldsToRemove.forEach((f:string)=>{
            var vIndex = requestData?.fields.findIndex(x=>x.name == f);
            requestData?.fields.splice(vIndex,1);
        })
    }

    const hasProperties = exportConfig.columns.some(col => col.name.startsWith('Property.'));
    
    if (pDataObject.hasPropertiesData && hasProperties) {
        requestData.propertiesViewName = pDataObject.propertiesData.viewName;
    }
    if (configurableRegister.isConfigured && configurableRegister.id) {
        requestData.registerId = configurableRegister.id;
    }

    requestData.viewName = pDataObject.viewName;
    requestData.maxRecords = -1;
    requestData.timezoneOffset = -(new Date().getTimezoneOffset());
    requestData.timezoneName = requestData?.timezoneName ?? Intl.DateTimeFormat().resolvedOptions().timeZone;


    if (pRequestData) {
        requestData = Object.assign(requestData, pRequestData);
    }
    
    if (pExportConfig) {
        exportConfig = Object.assign(exportConfig, pExportConfig);
        exportConfig.timezoneOffset = -(new Date().getTimezoneOffset());
    }

    return exportData("/nt/api/export/" + name + "." + pFormat, {
        requestData: requestData,
        exportConfig: exportConfig
    });
}

enum ExportFormat {
    Excel = 'xlsx',
    Pdf = 'pdf',
}

enum SummaryAggregate {
    SUM = 'SUM',
    AVG = 'AVG',
    MIN = 'MIN',
    MAX = 'MAX',
    COUNT = 'COUNT',
    COUNT_D = 'COUNT_D',
}

interface IExportConfig {
    /** Override for the name of the exported file */
    fileName?: string,
    sheetName: string,
    includeTitleRow: boolean,
    includeHeader: boolean,
    autoFitColumns: boolean,
    includeDataTypes: boolean,
    includeFilter: boolean,
    exportAsImportTemplate: boolean,
    includePivotTable: boolean,
    includeExportedDateTime: boolean,
    columns: Array<IExportConfigColumn>,
    host: string,
    contextId?: number,
    subSelectConfig?:Array<any>,
    created?: Date,
    /** Unix timezone name. I.e. 'Europe/Vilnius' */
    timezoneName?: string,
    /** Whether to localize date time values in data exports based in provided timezoneName. Defaults to false (keep UTC). */
    localizeDateTime: boolean,
    plainExport: boolean,
    [key: string]: any;
}

class ExportConfig implements IExportConfig {
    [key: string]: any;
    sheetName: string = 'Data Export';
    includeHeader: boolean = true;
    includeTitleRow: boolean = true;
    autoFitColumns: boolean = true;
    includeDataTypes: boolean = false;
    includeFilter: boolean = false;
    exportAsImportTemplate: boolean = false;
    includeExportedDateTime: boolean = true;
    includePivotTable: boolean = true;
    localizeDateTime: boolean = false;
    plainExport: boolean = false;
    host = window.location.origin;
    columns: IExportConfigColumn[] = [];
}

export interface IExportConfigColumn {
    name: string;
    caption?: string;
    width?: number;
    dataType?: string;
    maxLength?: number;
    link?: string;
    conditionFormats?: Array<IConditionalFormat>;
    summaryAggregate?: SummaryAggregate | string;
    format?: string;
    [key: string]: any;
}

class ExportConfigColumn implements IExportConfigColumn {
    [key: string]: any;
    name: string;
    caption?: string;   
    width?: number;
    dataType?: string;
    maxLength?: number;
    link?: string;
    summaryAggregate: SummaryAggregate | string;
    conditionFormats?: IConditionalFormat[];
}

class ConditionalFormat implements IConditionalFormat {
    [key: string]: any;
    formatConditionType: FormatConditionType;
    operatorType: ConditionalFormatOperatorType;
    formula1?: string;
    formula2?: string;
    fontColor?: string;
    backgroundColor?: string;
}

interface IConditionalFormat {
    formatConditionType: FormatConditionType,
    operatorType: ConditionalFormatOperatorType,
    formula1?: string,
    formula2?: string,
    fontColor?: string,
    backgroundColor?: string,
    [key: string]: any;
}

/**
 * Represents the operator type of conditional format and data validation.
 */
enum ConditionalFormatOperatorType {
    /**
     * Represents Between operator of conditional format and data validation.
     */
    Between,
    /**
     * Represents Equal operator of conditional format and data validation.
     */
    Equal,
    /**
     * Represents GreaterThan operator of conditional format and data validation.
     */
    GreaterThan,
    /**
     * Represents GreaterOrEqual operator of conditional format and data validation.
     */
    GreaterOrEqual,
    /**
     * Represents LessThan operator of conditional format and data validation.
     */
    LessThan,
    /**
     * Represents LessOrEqual operator of conditional format and data validation.
     */
    LessOrEqual,
    /**
     * Represents no comparison.
     */
    None,
    /**
     * Represents NotBetween operator of conditional format and data validation.
     */
    NotBetween,
    /**
     * Represents NotEqual operator of conditional format and data validation.
     */
    NotEqual,
}

/// <summary>Conditional format rule type.</summary>
enum FormatConditionType {
    /// <summary>
    /// This conditional formatting rule compares a cell value
    /// to a formula calculated result, using an operator.
    /// </summary>
    CellValue,
    /// <summary>
    /// This conditional formatting rule contains a formula to
    /// evaluate. When the formula result is true, the cell is
    /// highlighted.
    /// </summary>
    Expression,
    /// <summary>
    /// This conditional formatting rule creates a gradated
    /// color scale on the cells.
    /// </summary>
    ColorScale,
    /// <summary>
    /// This conditional formatting rule displays a gradated
    /// data bar in the range of cells.
    /// </summary>
    DataBar,
    /// <summary>
    /// This conditional formatting rule applies icons to cells
    /// according to their values.
    /// </summary>
    IconSet,
    /// <summary>
    /// This conditional formatting rule highlights cells whose
    /// values fall in the top N or bottom N bracket, as
    /// specified.
    /// </summary>
    Top10,
    /// <summary>
    /// This conditional formatting rule highlights unique
    /// values in the range.
    /// </summary>
    UniqueValues,
    /// <summary>
    /// This conditional formatting rule highlights duplicated
    /// values.
    /// </summary>
    DuplicateValues,
    /// <summary>
    /// This conditional formatting rule highlights cells
    /// containing given text. Equivalent to using the SEARCH()
    /// sheet function to determine whether the cell contains
    /// the text.
    /// </summary>
    ContainsText,
    /// <summary>
    /// This conditional formatting rule highlights cells that
    /// do not contain given text. Equivalent of using SEARCH()
    /// sheet function to determine whether the cell contains
    /// the text or not.
    /// </summary>
    NotContainsText,
    /// <summary>
    /// This conditional formatting rule highlights cells in the
    /// range that begin with the given text. Equivalent to
    /// using the LEFT() sheet function and comparing values.
    /// </summary>
    BeginsWith,
    /// <summary>
    /// This conditional formatting rule highlights cells ending
    /// with given text. Equivalent to using the RIGHT() sheet
    /// function and comparing values.
    /// </summary>
    EndsWith,
    /// <summary>
    /// This conditional formatting rule highlights cells that
    /// are completely blank. Equivalent of using LEN(TRIM()).
    /// This means that if the cell contains only characters
    /// that TRIM() would remove, then it is considered blank.
    /// An empty cell is also considered blank.
    /// </summary>
    ContainsBlanks,
    /// <summary>
    /// This conditional formatting rule highlights cells that
    /// are not blank. Equivalent of using LEN(TRIM()). This
    /// means that if the cell contains only characters that
    /// TRIM() would remove, then it is considered blank. An
    /// empty cell is also considered blank.
    /// </summary>
    NotContainsBlanks,
    /// <summary>
    /// This conditional formatting rule highlights cells with
    /// formula errors. Equivalent to using ISERROR() sheet
    /// function to determine if there is a formula error.
    /// </summary>
    ContainsErrors,
    /// <summary>
    /// This conditional formatting rule highlights cells
    /// without formula errors. Equivalent to using ISERROR()
    /// sheet function to determine if there is a formula error.
    /// </summary>
    NotContainsErrors,
    /// <summary>
    /// This conditional formatting rule highlights cells
    /// containing dates in the specified time period. The
    /// underlying value of the cell is evaluated, therefore the
    /// cell does not need to be formatted as a date to be
    /// evaluated. For example, with a cell containing the
    /// value 38913 the conditional format shall be applied if
    /// the rule requires a value of 7/14/2006.
    /// </summary>
    TimePeriod,
    /// <summary>
    /// This conditional formatting rule highlights cells that
    /// are above or below the average for all values in the
    /// range.
    /// </summary>
    AboveAverage,
}

export { 
    exportData,
    dataObjectExportData,
    IExportConfig, ExportConfig,
    IExportConfigColumn, ExportConfigColumn,
    IConditionalFormat, ConditionalFormat,
    ExportFormat,
    SummaryAggregate,
    ConditionalFormatOperatorType,
    FormatConditionType,
}